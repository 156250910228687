<template>
  <div class="place-an-order">
    <div class="banner" style="font-size: 0px" ref="wrappers">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in orderData.infoImageUrls" :key="index">
          <picture>
            <source :srcset="item.imageUrlWebp" type="image/webp" />
            <img :srcset="item.imageUrl" class="place-bottom-img" />
          </picture>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="order-wrapper"
      :style="{ backgroundColor: orderData.formColour }"
      id="btn"
      ref="btn"
    >
      <div class="order">
        <van-form>
          <van-field
            v-model="order.receiverName"
            center
            name="您的姓名"
            label="您的姓名"
            placeholder="请输入收货人姓名"
            class="field-top"
          />
          <van-field
            v-model="order.mobile"
            center
            name="手机号码"
            label="手机号码"
            type="number"
            placeholder="请输入手机号"
            maxlength="11"
          />
          <van-field
            v-model="order.smsCode"
            center
            name="验证码"
            label="验证码"
            type="number"
            placeholder="请输入验证码"
            maxlength="6"
          >
            <template #button>
              <van-button
                style="width: 7em"
                @click="_sendSmsCode"
                :disabled="authTime !== '获取验证码'"
                size="mini"
                type="primary"
              >
                {{ authTime }}
              </van-button>
            </template>
          </van-field>
          <!-- <van-field
            v-model="order.iccid"
            center
            name="iccid"
            label="ICCID"
            type="number"
            placeholder="请输入iccid"
            maxlength="19"
          /> -->
          <van-field
            v-if="orderData.idNumberFlag"
            v-model="order.receiverIdCard"
            center
            maxlength="18"
            name="身份证号"
            label="身份证号"
            placeholder="请输入身份证号码"
            class="field-top"
          >
          </van-field>
          <van-field
            center
            readonly
            clickable
            name="area"
            :value="value"
            label="所在地区"
            placeholder="点击选择省市区"
            @click="showArea = true"
          >
            <template #button>
              <div class="area-button-box">
                <van-icon @click.stop="clearValue" v-show="value !== ''" name="close" />
              </div>
            </template>
          </van-field>
          <van-popup v-model="showArea" position="bottom">
            <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
          </van-popup>
          <van-field
            v-model="order.address"
            type="textarea"
            name="详细地址"
            label="详细地址"
            placeholder="如街道、小区门牌等详细地址"
          />
        </van-form>

        <van-checkbox
          v-model="checked"
          icon-size="14px"
          shape="square"
          class="search-check search-agreement"
          checked-color="#ee0a24"
        >
          我已阅读并同意
          <span @click.stop="agreementClick">《入网协议》</span>
          <span @click.stop="noticeClick">《信息收集公告》</span>
        </van-checkbox>
        <div class="btn-wrapper">
          <van-button
            type="danger"
            class="place-btn"
            @click="placeOrder"
            :style="{ backgroundColor: orderData.buttonColour }"
            :loading="loading"
          >
            {{ buttonName? buttonName: '提交申请'}}
          </van-button>
        </div>
      </div>
    </div>
    <!-- 底部图片 -->
    <div
      style="font-size: 0px"
      v-if="orderData.detailDrawingImgUrls && orderData.detailDrawingImgUrls.length"
    >
      <picture v-for="(item, index) in orderData.detailDrawingImgUrls" :key="index">
        <source :srcset="item.imageUrlWebp" type="image/webp" />
        <img :srcset="item.imageUrl" class="place-bottom-img" />
      </picture>
    </div>
    <!-- 协议 -->
    <AgreementPopup ref="agreementPopup" />
    <!-- 协议二 -->
    <NoticePopup ref="noticePopup" />
  </div>
</template>

<script>
  import {
    getWebPage,
    getArea,
    getNumberCode,
    getChange,
    postChangeCard,
  } from "@/api/api";
  import AgreementPopup from "../placeOrder/modules/AgreementPopup.vue";
  import NoticePopup from "../placeOrder/modules/NoticePopup.vue";
  import { checkIdCard } from "@/utils";

  export default {
    name: "placeAnOrder",
    data() {
      return {
        loading: false,
        showArea: false,
        value: "", //地区
        area: {
          province: "",
          city: "",
          county: "",
        },
        areaList: [], //城市列表数据
        // 表单数据
        order: {
          receiverName: "",
          mobile: "",
          smsCode: "",
          address: "",
          receiverIdCard: "",
          iccid: "",
        },
        cityCode: "",
        orderData: "", //页面显示数据
        checked: false, //是否勾选协议
        // 短信
        authTime: "获取验证码",
        btnShow: true,
        // 协议
        showPopup: false,
        fixedBtnShow: false,
        fixedLength: null,
        fixedFormShow: false,
        buttonName: false,
      };
    },
    components: { AgreementPopup, NoticePopup },
    created() {
      // this.$store.commit("setPathName", window.location.pathname);
      console.log('9969', this.$route.path)
      this.order.iccid = this.$route.query.iccid
      this._getData(this.$route.query)
    },
    methods: {
      // 清除省市区
      clearValue() {
        this.value = "";
        this.area.province = "";
        this.area.city = "";
        this.area.county = "";
      },
      // 提交申请
      placeOrder() {
        if (this.order.receiverName === "") {
          this.$toast("请输入姓名");
          return;
        }
        if (this.orderData.idNumberFlag) {
          if (this.order.receiverIdCard === "") {
            this.$toast("请输入身份证号码");
            return;
          }
          const checkCard = checkIdCard(this.order.receiverIdCard);
          if (!checkCard.pass) {
            this.$toast("身份证号格式错误");
            return;
          }
        }
        if (this.order.mobile === "") {
          this.$toast("请输入手机号");
          return;
        }
        if (this.order.smsCode === "") {
          this.$toast("请输入验证码");
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.order.mobile)) {
          this.$toast("请输入正确的11位手机号");
          return;
        }
        if (
          this.area.province === "" &&
          this.area.city === "" &&
          this.area.county === ""
        ) {
          this.$toast("请选择所在地区（省/市/区）");
          return;
        }
        if (this.order.address === "") {
          this.$toast("请输入详细地址");
          return;
        }
        // if (!this.order.iccid || this.order.iccid.length<19) {
        //   return this.$toast("请输入正确的ICCID");
        // }

        if (!this.checked) {
          this.$toast("请阅读并同意相关协议");
          return;
        }
        const params = {
          changeCardIccid: this.order.iccid,
          receiverName: this.order.receiverName,
          receiverMobile: this.order.mobile,
          smsCode: this.order.smsCode,
          receiverAddress: this.order.address, //详细地址
          receiverIdCard: this.order.receiverIdCard, //身份证号
          receiverProvince: this.area.province,
          receiverCity: this.area.city,
          receiverCounty: this.area.county,
          productId: this.orderData.productId,
          webPageId: this.$route.query.page,
        };
        this.loading = true

        postChangeCard(params).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$toast(res.message);
            this.clearFormData();
            this.$router.push(`/updateResult/${res.result.orderNo}`);
          } else {
            this.$toast(res.message);
          }
          this.loading = false
        }).catch(err => {
          this.loading = false;
        })
      },
      // 清除表单
      clearFormData() {
        Object.keys(this.order).forEach(item => {
          this.order[item] = ''
        })
        this.clearValue();
      },
      // 地址
      onConfirm(values) {
        this.cityCode = values[values.length - 1].code;
        this.area.province = values[0].name;
        this.area.city = values[1].name;
        this.area.county = values[2].name;
        this.value = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join("/");
        this.showArea = false;
      },
      // 获取页面数据
      _getData({ page }) {
        this.$store.commit("setClickId", window.location.href);
        this._getAreaList({ id: page });
        getWebPage({ id: page }).then((res) => {
          console.log(JSON.parse(JSON.stringify(res)));
          if (res && res.code === 200) {
            this.orderData = res.result.webPageAllInfoVO;
            this.buttonName = res.result.webPageAllInfoVO.buttonName

            document.title = res.result.webPageAllInfoVO.pageName;
            this._getChange(this.$route.query.mobile);

          } else if (res && res.code === 201 && res.message === "产品已下架") {
            this.$router.push("/soldOut");
          } else {
            this.$router.push("/404");
          }
        });
      },
      // 获取地址列表
      _getAreaList(argument) {
        getArea(argument).then((res) => {
          if (res && res.code === 200) {
            const { province_list, city_list, county_list } = res.result;
            const province_list_hash_map = {};
            // debugger
            Object.keys(province_list).forEach((code) => {
              province_list_hash_map[code.slice(0, 2)] = {
                value: province_list[code],
                hasChild: false,
              };
            });
            // console.log('123', province_list_hash_map)
            Object.keys(city_list).forEach((code) => {
              if (province_list_hash_map[code.slice(0, 2)]) {
                province_list_hash_map[code.slice(0, 2)].hasChild = true;
              }
            });
            Object.keys(province_list).forEach((code) => {
              if (!province_list_hash_map[code.slice(0, 2)].hasChild) {
                delete province_list[code];
              }
            });
            Object.keys(city_list).forEach((code) => {
              if (!province_list[code.slice(0, 2) + "0000"]) {
                delete city_list[code];
              }
            });
            Object.keys(county_list).forEach((code) => {
              if (!city_list[code.slice(0, 4) + "00"]) {
                delete county_list[code];
              }
            });
            // 重新赋值为六位地区编码
            let counter1 = 2;
            let counter2 = 2;
            for (const key in county_list) {
              // 东莞区下的县级
              if (key.toString().startsWith('441900')) {
                const newKey = 4419 + counter1.toString().padStart(2, '0');
                county_list[newKey] = county_list[key];
                delete county_list[key];
                counter1++;
              }
              // 中山区下的县级
              if (key.toString().startsWith('442000')) {
                const newKey = 4420 + counter2.toString().padStart(2, '0');
                county_list[newKey] = county_list[key];
                delete county_list[key];
                counter2++;
              }
            }
            this.areaList = { province_list, city_list, county_list };
          } else {
            this.$toast(res.message);
          }
        });
      },
      _getChange(mobile) {
        getChange({ mobile }).then(res => {
          console.log(res)
          if (res && res.code === 200) {
            this.order = Object.assign(this.order, res.result)
            this.area.city = res.result.city
            this.area.county = res.result.county
            this.area.province = res.result.province
            this.value = `${res.result.province}/${res.result.city}/${res.result.county}`
            console.log('99666', this.value)
          }
        })
      },
      // 获取短信验证码
      _sendSmsCode() {
        if (!this.order.mobile) {
          this.$toast("请输入手机号");
          return;
        }
        getNumberCode({ mobile: this.order.mobile }).then((res) => {
          this.$toast(res.message);
          this.startTime();
          // this.btnShow = false;
        });
      },
      startTime() {
        this.authTime = 60;
        this.timer = setInterval(() => {
          this.authTime--;
          if (this.authTime <= 0) {
            this.clearTime();
            this.btnShow = true;
          }
        }, 1000);
      },
      clearTime() {
        if (this.timer) {
          this.authTime = "获取验证码";
          clearInterval(this.timer);
        }
      },
      close_Click() {
        this.clearTime();
        this.showPopup = false;
      },
      // 协议
      agreementClick() {
        console.log("协议");
        this.$refs.agreementPopup.show();
      },
      noticeClick() {
        console.log("公告");
        this.$refs.noticePopup.show();
      },
    },
  };
</script>

<style lang="less" scoped>
  .place-an-order {
    min-height: 100%;
    .banner {
      // height 375px
      position: relative;
      .notice {
        position: absolute;
        top: 10%;
        left: 2%;
        width: 45%;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        .notice-swipe {
          height: 30px;
          line-height: 30px;
          color: #fff;
        }
      }
    }
    .order-wrapper {
      background-color: #fead5f;
      padding: 20px 18px;
      .form-header {
        border-radius: 4px;
        background-color: #fff;
        .form-name {
          padding: 10px 20px;
          font-size: 15px;
          color: #333;
          font-weight: 700;
        }
        .form-sales {
          padding: 10px 16px;
          font-size: 14px;
          color: #b9b5b5;
          font-weight: 500;
        }
        .text-left {
          text-align: left;
        }
        .text-right {
          text-align: right;
        }
      }
      .order {
        padding: 4px;
        border-radius: 4px;
        background-color: #fff;
        .search-agreement {
          span {
            color: #3d94f6;
          }
        }
        .field-imitate {
          padding: 0 16px;
          .field-label {
            padding: 10px 0;
            font-size: 15px;
            font-weight: bold;
            color: #333;
            text-align: left;
          }
          .field-content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(235, 237, 240, 0.6);
            ul {
              display: flex;
              li {
                .title {
                  color: #86909c;
                  font-size: 14px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
    .search-check {
      background-color: #fff;
      padding: 15px 10px 15px 18px;
      font-size: 11px;
      color: #666;
      font-weight: 500;
    }
    .btn-wrapper {
      display: flex;
      padding: 2px 10% 15px 10%;
      background-color: #fff;
      .place-btn {
        flex: 1;
        border-radius: 23px;
        background-color: #ec4e2a;
        font-size: 17px;
        font-weight: bold;
        border: none;
      }
    }
    .place-bottom-img {
      width: 100%;
      // height: 100%;
    }
  }
  ::v-deep .van-field__label {
    width: 5em;
    display: inline-block;
    text-align: justify;
    text-align-last: justify
  }
  ::v-deep .van-cell__title {
    font-size: 15px;
    font-weight: bold;
    color: #333;
  }
  ::v-deep .van-button--primary {
    background-color: #fff;
    color: #d41010;
    border: 1px solid #ecbcbc;
  }
  :v-deep .van-dialog {
    border-radius: 8px;
  }
</style>
